import {
  NgxMatDatetimePickerModule,
  NgxMatTimepickerModule,
} from '@angular-material-components/datetime-picker';
import { NgxMatMomentModule } from '@angular-material-components/moment-adapter';
import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatPaginatorModule } from '@angular/material/paginator';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { NgxPaginationModule } from 'ngx-pagination';
import { AngularMaterialModule } from '../angular-material/angular-material.module';
import { TranslateDirective } from '../language/translate.directive';
import { AutocompleteGoogleComponent } from './autocomplete-google/autocomplete-google.component';
import { ClassStylePipe } from './class-pipe/class-style.pipe';
import { ClientDataStorageService } from './clientDataStorage/client-data-storage.service';
import { ColumnSelectorComponent } from './column-selector/column-selector.component';
import { CustomRangePanelComponent } from './date-range-picker/custom-range-panel/custom-range-panel.component';
import { DateRangePickerComponent } from './date-range-picker/date-piker-range/date-range-picker.component';
import { DurationPipe } from './date/duration.pipe';
import { FormatMediumDatePipe } from './date/format-medium-date.pipe';
import { FormatMediumDatetimePipe } from './date/format-medium-datetime.pipe';
import { DrawPolygonComponent } from './draw-polygon/draw-polygon.component';
import { PolygonService } from './draw-polygon/draw-polygon.service';
import { DropdownSelectComponent } from './dropdown-select/dropdown-select.component';
import { IndicatorCardComponent } from './indicator-card/indicator-card.component';
import { ModalComponent } from './modal/modal.component';
import { ModulesFunctionsComponent } from './modules-functions/modules-functions.component';
import { NotificationModule } from './notification/notification.module';
import { NumberWithSpacesPipe } from './numeric/number-with-spaces.pipe';
import { OperatorMapCardComponent } from './operator-map-card/operator-map-card.component';
import { OperatorsCardComponent } from './operators-card/operators-card.component';
import { SharedLibsModule } from './shared-libs.module';
import { TableModule } from './table/table.module';
import { TrackingComponent } from './tracking/tracking.component';


@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],

  declarations: [
    DurationPipe,
    FormatMediumDatetimePipe,
    FormatMediumDatePipe,
    TranslateDirective,
    ColumnSelectorComponent,
    DrawPolygonComponent,
    IndicatorCardComponent,
    ModalComponent,
    ModulesFunctionsComponent,
    DropdownSelectComponent,
    AutocompleteGoogleComponent,
    TrackingComponent,
    CustomRangePanelComponent,
    DateRangePickerComponent,
    OperatorsCardComponent,
    OperatorMapCardComponent,
    NumberWithSpacesPipe,
    ClassStylePipe  
  ],
  imports: [SharedLibsModule, RouterModule, NgxPaginationModule, MatPaginatorModule,NgxDaterangepickerMd.forRoot()],
  providers: [ClientDataStorageService, PolygonService],
  exports: [
    CommonModule,
    FormsModule,
    NgbModule,
    ReactiveFormsModule,
    AngularMaterialModule,
    NgxMaterialTimepickerModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    NgxMatMomentModule,
    NotificationModule,
    DurationPipe,
    FormatMediumDatetimePipe,
    FormatMediumDatePipe,
    TranslateDirective,
    TranslateModule,
    RouterModule,
    ColumnSelectorComponent,
    DrawPolygonComponent,
    IndicatorCardComponent,
    ModalComponent,
    ModulesFunctionsComponent,
    DropdownSelectComponent,
    TableModule,
    AutocompleteGoogleComponent,
    NgxPaginationModule,
    TrackingComponent,
    OperatorsCardComponent,
    OperatorMapCardComponent,
    NumberWithSpacesPipe,
    ClassStylePipe
  ],
})
export class SharedModule {}
