import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NotificationService } from '../../shared/notification/notification.service';
import { I18nTranslateService } from '../../shared/utils/i18n-translate.service';
import { LoginVM } from '../model/login-vm';
import { LoginService } from '../service/login.service';

@Component({
  selector: 'milestone-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  authenticationError = false;
  showPassword = false;
  loading = false;

  loginForm = this.fb.group({
    username: [null, [Validators.required, Validators.maxLength(50)]],
    password: [null, [Validators.required, Validators.maxLength(60)]],
    rememberMe:[false],
  });


  constructor(
    private fb: FormBuilder,
    private loginService: LoginService,
    private router: Router,
    private notification: NotificationService,
    private i18nTranslateService : I18nTranslateService
  ) {}

  ngOnInit(): void {}

  login(): void {
    if (this.isValid()) {
      const longinVM = this.setLoginVM();
      this.onClick();
      this.loginService.login(longinVM).subscribe({
       next: () => {
          this.notification.showMessageSuccess('authentication successful');
          this.authenticationError = false;
          this.router.navigate(['/']);
        },
       error: () => {
          this.authenticationError = true;
          this.setTranslatedText("login.home.messages.error.authentication");
          this.router.navigate(['/login']);
        }
    });
    }
  }

  async setTranslatedText(key: string): Promise<void> {
    try {
      const translatedText = await this.i18nTranslateService.translateText(key);
      this.notification.showMessageError(translatedText);
    } catch (error) {
      console.error('Translation error:', error);
    }
  }
  
  private isValid(): boolean {
    return (
      !this.loginForm.get('username')?.invalid &&
      !this.loginForm.get('password')?.invalid
    );
  }

  private onClick(): void {
    this.loading = true;

    // Simulate a delay for demonstration purposes
    setTimeout(() => {
      this.loading = false;
      // Add your actual logic here
    }, 3000);
  }

  private setLoginVM(): LoginVM {
    return  {
      rememberMe: this.loginForm.get('rememberMe')?.value?? false,
      username: this.loginForm.get('username')?.value,
      password: this.loginForm.get('password')?.value,
      
    };
  }

}
