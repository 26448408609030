import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'classStyle',
  pure: true, // Ensures the pipe only runs when inputs change
})
export class ClassStylePipe implements PipeTransform {
  transform(rate: number, params: { param1: number; param2: number } | null | undefined): string {
    if (params) {
      if (rate <= params.param1) {
        return 'text-success';
      } else if (rate > params.param1 && rate <= params.param2) {
        return 'text-orange';
      } else {
        return 'rate-anim';
      }
    }
    return '';
  }
}
