import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import {
  Observable,
  catchError,
  lastValueFrom,
  map,
  tap,
  throwError,
} from 'rxjs';
import { ApplicationConfigService } from '../../../core/config/application-config.service';
import { AppConstant } from '../../../shared/app-constant';
import { ClientDataStorageService } from '../../../shared/clientDataStorage/client-data-storage.service';
import { NotificationService } from '../../../shared/notification/notification.service';
import { UserType } from '../enum/User-type.enum';
import { PagenationsUserVM, UserVM } from '../models/user-vm.model';
import { Router } from '@angular/router';
import { I18nTranslateService } from '../../../shared/utils/i18n-translate.service';
import { Pagination } from '../../../core/request/request.model';
import { RequestSearch } from '../../../core/specification/requestSearch';
import { createRequestOption } from '../../../core/request/request-util';

@Injectable({
  providedIn: 'root',
})
export class AccountService {
  private currentUser: UserVM | null = null;

  constructor(
    private router: Router,
    private http: HttpClient,
    private notification: NotificationService,
    private dataStorage: ClientDataStorageService,
    private i18nTranslateService: I18nTranslateService,
    private applicationService: ApplicationConfigService,
  ) {}

  getUserDetails(): Promise<UserVM> {
    return lastValueFrom(
      this.http
        .get<UserVM>(
          this.applicationService.getEndpointFor('/users/account'),
          AppConstant.httpOptions
        )
        .pipe(
          tap((usr: UserVM) => {
            this.currentUser = usr;
            this.dataStorage.saveUser(usr);
          }),
          catchError((err) => {
            console.error('Error:', err.error.message);
            this.setTranslatedText('login.home.messages.error.authentication');
            this.dataStorage.signOut();
            this.router.navigate(['/login']);
            // Re-throw the error to propagate it up the observable chain
            return throwError(err);
          })
        )
    );
  }

  query( pagination: Pagination, filters?: RequestSearch | null): Observable<PagenationsUserVM> {
    const paramsQuery = createRequestOption(pagination);
    return this.http
      .post<PagenationsUserVM>(
        this.applicationService.getEndpointFor('/admin/users/query'),
        filters,
        {headers: AppConstant.httpOptions.headers, params: paramsQuery}
      );
  }

  getUsersByFirstnameOrLastname(name: string): Observable<UserVM[]> {
    return this.http
      .get<UserVM[]>(
        this.applicationService.getEndpointFor(`/users/${name}`),
        AppConstant.httpOptions
      )
      .pipe(map((accounts) => accounts));
  }

  getAllOperators(): Observable<UserVM[]> {
    return this.http
      .get<UserVM[]>(
        this.applicationService.getEndpointFor(`/admin/users/all`),
        AppConstant.httpOptions
      )
      .pipe(map((resp) => resp));
  }

  getAllOperatorsByName(name: string): Observable<UserVM[]> {
    return this.http
      .get<UserVM[]>(
        this.applicationService.getEndpointFor(
          `/users/userType-name/${UserType.OPERATOR}/${name}`
        ),
        AppConstant.httpOptions
      )
      .pipe(map((resp) => resp));
  }

  getOperateurs(): Observable<UserVM[]> {
    return this.http
      .get<UserVM[]>(
        this.applicationService.getEndpointFor('/users/operators'),
        AppConstant.httpOptions
      )
      .pipe(map((response) => response));
  }

  hasAnyAuthority(rights: string[] | string): boolean {
    if (!this.currentUser) {
      return false;
    }
    if (!Array.isArray(rights)) {
      rights = [rights];
    }
    return this.currentUser
      .getfunctions()
      .some((right: string) => rights.includes(right));
  }

  protected async setTranslatedText(key: string): Promise<void> {
    try {
      const translatedText = await this.i18nTranslateService.translateText(key);
      this.notification.showMessageError(translatedText);
    } catch (error) {
      console.error('Translation error:', error);
    }
  }
}
