<div>
  <!-- left side -->
  <section class="auth bg-base d-flex flex-wrap">
    <div class="auth-left-55 d-none d-lg-block">
      <div class="d-flex align-items-center flex-column h-100 justify-content-center prelative">
        <img src="assets/images/png/login-home.png" alt="login home" class="h-100 w-100" />
        <!--<div class="banner-bootm">
          <div class="logo-container">
          <div class="logo">
            <a href="#"> <img src="assets/images/png/ceoslogo.png" alt="ceoslogo"  /></a>
          </div>
            <div class="divider"></div>
            <div class="powered-by">
              Powered by CEOS TECHNOLOGY
            </div>
          </div>
        </div>-->
      </div>
    </div>

    <!-- right side form sign in -->
    <div class="auth-right-45 px-24 d-flex flex-column">
      <!-- need help -->
      <div class="pl-4 text-center fsz-12 ml-rem-76 mt-rem-3">
        <span milestoneTranslate="login.home.need-help">need a help or an information?</span>
        <a class="fw-bold milestone-color text-decoration-none pl-rem-1" milestoneTranslate="login.home.contact-suport">
          contact support
        </a>
      </div>

      <!-- form sign in -->
      <div class="max-w-464-px mx-auto w-100 justify-content-center mt-rem-36 h-75">
        <div>
          <h4 class="tittle" milestoneTranslate="login.home.title">Sign In to your Account</h4>
          <p class="mb-32 sub-title" milestoneTranslate="login.home.text">
            Backoffice space milestone
          </p>
        </div>

        <div class="alert alert-danger" *ngIf="authenticationError" milestoneTranslate="login.home.messages.error.authentication" data-cy="loginError">
          <strong>Failed to sign in!</strong> Please check your credentials and try again.
        </div>

        <form role="form" (ngSubmit)="login()" [formGroup]="loginForm">
          <div class="icon-field mb-16">
            <span class="icon top-50 translate-middle-y">
              <iconify-icon icon="solar:phone-calling-linear"></iconify-icon>
            </span>
            <input
              type="text"
              class="form-control h-56-px bg-neutral-50 radius-12"
              placeholder="{{ 'login.home.input.placeholder.username' | translate }}"
              name="username"
              id="username"
              formControlName="username"
              #username
              data-cy="username"
            />
          </div>
          <div class="position-relative mb-20">
            <div class="icon-field">
              <span class="icon top-50 translate-middle-y">
                <iconify-icon icon="solar:lock-password-outline"></iconify-icon>
              </span>
              <input
                [type]="showPassword ? 'text' : 'password'"
                class="form-control h-56-px bg-neutral-50 radius-12"
                placeholder="{{ 'login.home.input.placeholder.password' | translate }}"
                name="password"
                id="password"
                #password
                formControlName="password"
                data-cy="password"
              />
            </div>
            <span
              class="toggle-password cursor-pointer position-absolute end-0 top-50 translate-middle-y me-16 text-secondary-light"
              [ngClass]="{ 'ri-eye-off-line' : !showPassword, 'ri-eye-line': showPassword }"
              (click)="showPassword = !showPassword"
            ></span>
          </div>
          <div class="">
            <div class="d-flex justify-content-between gap-2">
              <div class="form-check style-check d-flex align-items-center">
                <input
                  class="form-check-input border border-neutral-300"
                  type="checkbox"
                  id="rememberMe"
                  name="rememberMe"
                  id="rememberMe"
                  #rememberMe
                  formControlName="rememberMe"
                  data-cy="rememberMe"
                />
                <label class="form-check-label" for="rememberMe" milestoneTranslate="login.home.input.remember-me">
                  Remember me
                </label>
              </div>
              
              <a [routerLink]="'/forgot-password'" class="text-primary-light fw-medium" milestoneTranslate="login.home.forget-password">
                Forgot Password?
              </a>
            </div>
            </div>

          <button type="submit" class="btn btn-milestone text-md btn-sm px-12 py-16 w-100 radius-13 mt-32">
            <ng-container *ngIf="!loading; else spinner">
              <span milestoneTranslate="login.home.input.sing-in"> Sign In</span>
            </ng-container>
            <ng-template #spinner>
              <div class="spinner-border text-light" role="status">
                <span class="sr-only" milestoneTranslate="login.home.input.sing-in"> Sign In</span>
              </div>
            </ng-template>
          </button>

        </form>
      </div>
      <div class="mt-rem-19 text-center text-sm">
        <div class="mb-rem-3 text-center">
          <!--<img src="assets/images/svg/footer-milesone.svg" alt="footer-milesone" class="h-20 w-20" />-->
          <a href="https://www.ceostechnology.ma" target="_blank"> <img src="assets/images/png/ceoslogo.png" alt="ceoslogo"  style="width: 150px;" /></a>
        </div>
        <div class="footer">
          <img src="assets/images/png/c-circle.png" alt="c-circle" class="c-cirle">         
          <p class="text-gry">
            Powered by CEOS TECHNOLOGY All rights reserved
          </p>
        </div>
        <p class="mb-0"></p>
      </div>
    </div>
    
  </section>
</div>
