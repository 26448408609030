import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { IOperatorVM } from '../../location/model/operator-indicators.model';
import { IShipmentVM, ShipmentPagenationsVM } from '../models/shipment-vm.model';
import { ITrackingShipmentVM } from '../models/tracking-shipment-vm.model';
import { ApplicationConfigService } from './../../../core/config/application-config.service';
import { createRequestOption, setRequestOption } from './../../../core/request/request-util';
import { Pagination } from './../../../core/request/request.model';
import { RequestSearch } from './../../../core/specification/requestSearch';
import { AppConstant } from './../../../shared/app-constant';
import { IndicatorShipmentVM } from '../indicator-card/indicator-shipment-card.model';

@Injectable({
  providedIn: 'root',
})
export class ShipementService {
  constructor(
    private http: HttpClient,
    private applicationService: ApplicationConfigService
  ) {}

  getdetailShipment(idShipement: number): Observable<IShipmentVM> {
    return this.http.get<IShipmentVM>(
      this.applicationService.getEndpointFor(`/shipments/by-id-shipment/${idShipement}`),
      AppConstant.httpOptions
    );
  }

  getTrackingShipment(idShipement: number): Observable<ITrackingShipmentVM[]> {
    return this.http.get<ITrackingShipmentVM[]>(
      this.applicationService.getEndpointFor(`/tracking-shipments/${idShipement}`),
      AppConstant.httpOptions
    );
  }

  query( pagination: Pagination,  idAgency?: string, filters?: RequestSearch | null): Observable<ShipmentPagenationsVM> {
    let paramsQuery = createRequestOption(pagination);
    paramsQuery = setRequestOption(idAgency, "idAgency", paramsQuery);
    return this.http
      .post<ShipmentPagenationsVM>(
        this.applicationService.getEndpointFor(`/shipments/query`),
        filters,
        {headers: AppConstant.httpOptions.headers, params: paramsQuery}
      );
  }

  getIdicatorsShipment( idAgency: string): Observable<IndicatorShipmentVM[]> {
    return this.http
      .get<IndicatorShipmentVM[]>(
        this.applicationService.getEndpointFor(`/shipments/indicators/${idAgency}`),
        {headers: AppConstant.httpOptions.headers}
      );
  }

  getOperateursWithIndicator(): Observable<IOperatorVM[]> {
    return this.http
      .get<IOperatorVM[]>(this.applicationService.getEndpointFor('/shipments/operators-agency'), AppConstant.httpOptions)
      .pipe(map((response) => response));
  }

}
