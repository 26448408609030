import { Component, OnInit } from '@angular/core';
import { SidebarService } from '../sidebare/sidebar.service';
import { Router } from '@angular/router';
import { LoginService } from '../../login/service/login.service';
import { ClientDataStorageService } from '../../shared/clientDataStorage/client-data-storage.service';
import { AccountService } from './../../modules/user/services/user.service';
import { UserVM } from './../../modules/user/models/user-vm.model';
import { UserAffiliationVM } from '../../modules/user/models/user-affiliation-vm.model';
import { UserAffiliationService } from '../../modules/user/services/user-affiliation.service';

@Component({
  selector: 'milestone-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  user: UserVM | null = null;
  userAffiliation: UserAffiliationVM| null= null;

  constructor(
    private router: Router,
    private loginService: LoginService,
    private sidebarService: SidebarService,
    private clientDataStorgare: ClientDataStorageService,
    private accountService: AccountService,
    private userAffiliationService: UserAffiliationService
  ) {}

  ngOnInit(): void {
    if (this.clientDataStorgare.isAutenticated()) {
      this.loadUserDetails().then(() => {
        if(this.user){
          this.getUserAffiliation(this.user.idUser);
        }
      });
    }
  }

  async loadUserDetails(): Promise<void> {
    try {
      this.user = await this.accountService.getUserDetails();
    } catch (error) {
      // Handle the rejected promise (error)
      console.error("Failed to fetch user details:", error);
    }
  }
  

  toggleSidebar(): void {
    this.sidebarService.toggleSidebar();
  }

  async logout(): Promise<void> {
    this.loginService.logout();

    const navigationExtras = { replaceUrl: true };
    await this.router.navigateByUrl('', navigationExtras);

    location.reload();
    window.location.reload();
  }

  /* ==========================
      PRIVATE MESSAGE
  ============================== */

  private getUserAffiliation(idUser : number): void{
    this.userAffiliationService.getUsersAffiliationOfUser(idUser).subscribe( {
      next: (data) =>{
        this.userAffiliation = data;
        this.clientDataStorgare.saveAgency(this.userAffiliation?.agency);
      }, 
      error: (error) => console.error('Error fetching user affiliation:', error),
    })
  }
}
