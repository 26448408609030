import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { ApplicationConfigService } from 'src/app/core/config/application-config.service';
import { createRequestOption } from 'src/app/core/request/request-util';
import { Pagination } from 'src/app/core/request/request.model';
import { RequestSearch } from 'src/app/core/specification/requestSearch';
import { AppConstant } from 'src/app/shared/app-constant';
import { PagenationsUserAffiliationVM, UserAffiliationVM } from '../models/user-affiliation-vm.model';

@Injectable({
  providedIn: 'root',
})
export class UserAffiliationService {

  private basicUrl =  this.applicationService.getEndpointFor('/userAffiliations');
  private basicUrlAdmin =  this.applicationService.getEndpointFor('/admin/userAffiliations');
   
  constructor(
    private http: HttpClient,
    private applicationService: ApplicationConfigService,
  ) {}

  query( pagination: Pagination, filters?: RequestSearch | null): Observable<PagenationsUserAffiliationVM> {
    const paramsQuery = createRequestOption(pagination);
    return this.http
      .post<PagenationsUserAffiliationVM>(
       `${this.basicUrlAdmin}/query`,
        filters,
        {headers: AppConstant.httpOptions.headers, params: paramsQuery}
      );
  }

  getUsersAffiliationOfUser(idUser: number): Observable<UserAffiliationVM | null> {
    return this.http
      .get<UserAffiliationVM | null>(
        `${this.basicUrl}/user/${idUser}`,
        AppConstant.httpOptions
      )
      .pipe(map((resp) => resp));
  }

  


}
