<div class="status-card pointer">
    <div class="card-header">
      <div class="profile">
        <img src="assets/images/png/unknown.png" alt="Profile Image" class="profile-img">
        <div class="profile-info">
          <p>{{ operator?.firstname }}</p>
          <p>{{ operator?.lastname }}</p>
        </div>
      </div>
      <button class="role-btn" (click)="triggerAction()">Détails</button>
      <button class="role-btn" (click)="triggerMap()"><i _ngcontent-ng-c1766591684="" class="material-icons">near_me</i></button>
    </div>

    <!-- collected -->
      <div class="stats">
        <div class="stat">
          <div class="value">
            <p class="number blue"> {{ operator?.totalShipmentRamAssigned }}</p>
            <p class="description">Colis à ramasser</p>
          </div>
          <div class="stat-percentage red">
            <p>
              <span> {{ operator?.ratShipmentRam }} % </span>&nbsp;&nbsp; 
              <span> 
                {{ operator?.countShipmentRam }}/{{ (operator?.totalShipmentRamAssigned ?? 0) + (operator?.countShipmentRam ?? 0) }}

              </span>
            </p>
            <p class="description"> Colis ramassé</p>
          </div>
        </div>
  
        <!--<div class="divider"></div>-->
  
        <!-- delivered -->
        <div class="stat">
          <div class="value">
            <p class="number blue"> {{ operator?.totalShipmentLivAssigned }} </p>
            <p class="description">Colis à livrer</p>
          </div>
          <div class="stat-percentage green">
            <p>
              <span>{{ operator?.ratShipmentLiv }} % </span>&nbsp;&nbsp; 
              <span>
                {{ operator?.countShipmentLiv }}/{{ (operator?.totalShipmentLivAssigned ?? 0) + (operator?.countShipmentLiv ?? 0) }}
              </span>
            </p>
            <p class="description">Colis livré</p>        
          </div>
        </div>
      </div>
 
  </div>